@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
      font-family: 'Poppins', sans-serif;
      box-sizing: border-box;
    }
    *, *:before, *:after {
      box-sizing: inherit;
      margin: 0;
    }
    body {
      position: relative;
    }
    a {
      text-decoration: none;
    }
    p {
      margin: 0;
    }
    ::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    ::-webkit-scrollbar-thumb {
      background: rgba(250, 80, 61, 0.1); 
      border-radius: $borderRadius;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: rgba(250, 80, 61, 0.3); 
    }
}

@layer components {
  /* Sidebar Animation */
  .sidebar-text {
    @apply relative hover:text-SuggesterfyWhite hover:z-10 px-2
  }
  .sidebar-hover-bg {
    @apply before:absolute before:content-[''] before:w-0 before:h-full before:-z-10  
    before:bottom-0  before:rounded-[3px] before:left-0 before:bg-SuggesterfyPink  
    before:transition-all  before:duration-700 before:ease-in-out 
    hover:before:w-[140px] hover:before:-z-10
  }

  /* Post Image Slider */
  .image-slider {
    @apply relative grid grid-flow-col gap-3 overflow-x-auto overscroll-x-contain px-7 mt-3 snap-mandatory snap-x scroll-px-7 scroll-smooth
  }
  .image-container {
    @apply my-2 w-[160px] h-[160px] object-cover flex justify-center items-center overflow-hidden rounded-[12px] bg-SuggesterfyLightGrey shadow-lg snap-start cursor-pointer
  }
  .image-element {
    @apply max-w-xs hover:scale-110 transition duration-300 ease-in-out object-cover
  }

  /* Border Style */
  .suggesterfy-border {
    @apply w-11/12 border-b-[1px] border-solid border-SuggesterfyLightGrey mx-auto
  }
  .suggesterfy-divider {
    @apply my-2 border-b-[1px] border-solid border-SuggesterfyLightGrey mx-auto
  }
}

@layer utilities {
  .hover-color {
    color: #FFFFFF !important;
  }
}